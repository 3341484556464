import * as React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout"
import Grid from "../components/grid"
import GridBlock from "../components/gridblck"
import Links from "../components/links"
import Form from "../components/form"
import Logo from "../components/logo"
import ImagesStack from "../components/imagesstack"
import ImagesSlide from "../components/imagesslide"
import { graphql } from "gatsby"

// markup

const gridImages = ["grid-component--s"]
const gridBigComponentClasses = ["grid-component--b", "w-full", "text-center", "space-y-b"]

const IndexPage = ({ data }) => {

  const translations = {
    ...data.allMdx.nodes[0].frontmatter
  } 

  const formLabels = {
    mail: translations.mail,
    send: translations.send,
    error: translations.errorMessage,
    success: translations.successMsg,
    conditions : translations.conditions,
    conditions2 : translations.conditions2,
  }

  const stackAImagesA = ["b1.jpg", "a1.jpg", "c1.jpg"]
  const stackAImagesB = ["b2.jpg", "a2.jpg", "c2.jpg"]
  const stackAImagesC = ["b3.jpg", "a3.jpg", "c3.jpg"]
  const stackAImagesD = ["b4.jpg", "a4.jpg", "c4.jpg"]
  const stackAImagesMob = [
    "b1.jpg",
    "b2.jpg",
    "b3.jpg",
    "b4.jpg",
    "a1.jpg",
    "a2.jpg",
    "a3.jpg",
    "a4.jpg",
    "c1.jpg",
    "c2.jpg",
    "c3.jpg",
    "c4.jpg",
    "b1.jpg",
    "b2.jpg",
    "b3.jpg",
    "b4.jpg",
    "a1.jpg",
    "a2.jpg",
    "a3.jpg",
    "a4.jpg",
    "c1.jpg",
    "c2.jpg",
    "c3.jpg",
    "c4.jpg",
  ]

  return (
    <>
      <Layout>
        <Seo lang="fr" />
        <Grid classes={gridImages}>
        
          <div className="block hidden lg:block">
            <ImagesStack images={stackAImagesA} />
          </div>
          <div className="block hidden lg:block">
            <ImagesStack images={stackAImagesB} />
          </div>
          <div className="block hidden lg:block">
            <ImagesStack images={stackAImagesC} />
          </div>
          <div className="block hidden lg:block">
            <ImagesStack images={stackAImagesD} />
          </div>
          <div className="block image-slide-container lg:hidden">
            {/* mobile only */}
            <ImagesSlide images={stackAImagesMob} />
          </div>
        </Grid>
        <Grid classes={gridBigComponentClasses}>
          <GridBlock
            classes={[
              "w-100",
              "md:w-9/12",
              "mx-auto",
              "order-2",
              "md:order-first",
            ]}
          >
            {translations.access}
          </GridBlock>

          <GridBlock
            classes={["w-100", "mx-auto", "order-1", "md:order-first"]}
          >
            <Logo />
          </GridBlock>

          <GridBlock
            classes={[
              "w-100",
              "md:w-9/12",
              "mx-auto",
              "order-5",
              "md:order-first",
            ]}
          >
            <p>
              <a
                href="https://grafparis.com"
                target="_blank"
                rel="noreferrer"
              >
                François-Joseph
                <br></br>Graf<small class="sm-logo">PARIS</small>
              </a>
            </p>
          </GridBlock>

          <GridBlock
            classes={[
              "w-100",
              "md:w-9/12",
              "mx-auto",
              "md:-mt-6",
              "order-3",
              "md:order-first",
            ]}
          >
            <Form labels={formLabels} />
          </GridBlock>

          <GridBlock
            classes={[
              "w-100",
              "md:w-9/12",
              "mx-auto",
              "-mt-6",
              ":lg-mt-0",
              "order-4",
              "md:order-first",
            ]}
          >
            <p className="mb-3">
              37, rue de l'université<br></br> 75007 PARIS, FRANCE
            </p>
            <p>
              <a href="tel:+330144420222">+33 (0)1 44 42 02 22</a>
            </p>
          </GridBlock>

          <GridBlock
            classes={[
              "w-100",
              "md:w-9/12",
              "mx-auto",
              "-mt-6",
              ":lg-mt-0",
              "order-8",
              "md:order-first",
            ]}
          >
            <p className="mb-3">
              <a
                href="https://www.instagram.com/francois_joseph_graf_officiel/"
                target="_blank"
                rel="noreferrer"
              >
                {translations.follow}
              </a>
            </p>
            <Links classes={["w-8/12", "md:w-4/12", "lg:w-3/12", "mx-auto"]} />
          </GridBlock>
        </Grid>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { locale: { eq: "fr" } } }) {
      nodes {
        frontmatter {
          access
          mail
          send
          conditions
          conditions2
          follow
          errorMessage
          successMsg
        }
        body
      }
    }
  }
`

export default IndexPage
